@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

#modules {
  &.bonus {
    padding: 10px 0 80px;
  }

  padding: 0px 0 40px;
  margin: 0 auto;
  .title {
    text-align: center;
    margin: 0 0 30px;
    h2 {
      font-size: 20px;
    }
  }
  .modules {
    &__list {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__item {
      width: 100%;
      max-width: 680px;

      margin: 0;
      padding: 15px 40px;
      list-style: none;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background: #ffffff;
      border-bottom: 1px solid #eaeaea;
      border-radius: 2px;

      &--img {
        margin: 0;
      }

      &--title {
        font-weight: 500;
        font-size: 16px;
        color: #888888;
      }
    }
  }
}

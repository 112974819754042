@import "../../atoms/variables";

#video {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  overflow-x: hidden;
  iframe {
    width: 100vw;
  }
}
.video {
  &-block {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    video {
      max-width: 100%;
    }
    &:hover {
      .video-play {
        visibility: visible;
        opacity: 1;
        transition: all 0.3s;
        svg {
          width: 90px;
          height: 90px;
          opacity: 0.9;
          transition: all 0.3s;
        }
      }
    }
  }
  &-play,
  &-pause {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    svg {
      transition: all 0.3s;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      opacity: 0.4;
      box-shadow: 0px 0px 13px 0px #fffdfd;
      path{
        fill: $primary-blue;
      }
    }
  }
  &-pause {
    svg {
      &.play-ico {
        background-color: #fff;
      }
      &.pause-ico {
        display: none;
      }
    }
    &:hover {
      svg {
        opacity: 0.9;
        width: 90px;
        height: 90px;
        transition: all 0.3s;
      }
    }
  }
  &-play {
    visibility: hidden;
    opacity: 0;
    svg {
      &.play-ico {
        display: none;
      }
    }
  }
}

@import "../../UI/atoms/variables";

body.all-courses-page {
  background-color: $white;

  #banner {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;

    img {
      width: auto;
      min-width: 100vw;
    }
    .title {
      background-color: #fff;
      text-align: center;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      margin: auto;
      padding: 10px;
      width: 416px;
      height: 93px;
      max-width: 90vw;

      &__text {
        margin: 0;
      }
    }
  }

  .courses {
    &__content {
      padding: 50px 30px 25px;
      &:last-child {
        padding: 50px 30px 50px;
      }
    }
    &__list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      &--item {
        max-width: calc(33% - 20px);
        @media screen and (max-width: $tablet) {
          max-width: 100%;
          padding: 0 10px;
        }
      }
    }
  }
}

@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

.empowernefro-hemodiafiltração-e-diálise-em-agudos {
  #public {
    .public__content {
      @media screen and (min-width: ($tablet + 1)) {
        padding: 100px 15px 50px;
      }
      @media screen and (max-width: $tablet) {
        padding: 240px 15px 50px;
      }
    }
  }
  #header {
    .component-video__content {
      padding: 20px 15px 0;
    }
  }
}

.component-video {
  position: relative;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
  background-position: right;
  background-size: auto 100%;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 15px 80px;

    .title {
      margin: 0 0 20px;
      .title__desc {
        font-size: 18px;
      }
    }
    .title__desc {
      font-size: 18px;
      margin: 20px 0 0;
    }

    .slick-slide > div {
      text-align: center;
    }
  }

  &__banner {
    width: 95%;
    height: 70vh;
    min-height: 400px;
    position: relative;
    text-align: center;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: $tablet) {
      min-height: 680px;
    }
  }

  &__info {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;

    @media screen and (max-width: $tablet) {
      width: 100%;
    }

    .title__text {
      font-size: 18px;
    }

    &--price {
      margin: 40px 0;
      &.has-installments {
        .installments {
          font-size: 40px;
          font-weight: 500;
          text-transform: uppercase;
          margin: 5px 0;
          span {
            margin: 0 0 0 15px;
          }
          b {
            color: $green;
          }
        }
        .old-price,
        .best-price {
          font-size: 18px;
          line-height: 20px;
          span {
            font-size: 18px;
            line-height: 20px;
            color: $black;
            font-weight: 400;
          }
        }
        .best-price {
          span {
            margin: 0 5px;
          }
        }
      }

      .old-price {
        font-weight: 400;
        font-size: 24px;
        width: 100%;
        color: #888888;

        span {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          text-decoration-line: line-through;
          color: #222222;
        }
      }

      .best-price {
        width: 100%;
        font-weight: 400;
        font-size: 24px;
        line-height: 65px;
        color: #888888;

        span {
          font-weight: 700;
          font-size: 55px;
          color: #002e94;
          margin: 0 0 0 5px;

          @media screen and (max-width: $tablet) {
            font-size: 35px;
          }
        }
      }
    }

    &--buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      @media screen and (min-width: $tablet) {
        max-width: 60%;
        margin: 0 auto;
      }

      a,
      button {
        width: 48%;
        margin: 0 1%;
        height: 100%;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        outline: none;
        border: 0;
        color: $white;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: $tablet) {
          width: 100%;
          margin: 5px 0;
          height: auto;
          padding: 10px 0;
          font-size: 17px;
        }
      }

      &.start-now {
        background: $green;

        &:hover {
          background: #009922;
        }
      }
    }

    &--desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}
#popUp-egoi {
  position: fixed;
  top: 0;
  left: -110vw;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 11;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s linear;
  &.open {
    left: 0;
    opacity: 1;
  }
  button {
    background-color: $primary-blue;
    border: 0;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 50%;
    color: $white;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 -22px 490px;
    z-index: 2;
    cursor: pointer;
    @media screen and (max-width: 628px) {
      margin: 0 0 -22px 78vw;
    }
  }
  iframe {
    border-radius: 20px;
    width: 80vw;
    max-width: 500px;
    height: fit-content;
    max-height: 500px;
  }
}

.component__info--buttons {
  width: 100%;

  @media screen and (max-width: $tablet) {
    max-width: unset;
  }
  .title__text {
    font-size: 22px;
    @media screen and (max-width: $tablet) {
      font-size: 18px;
    }
  }
}

@import "../../atoms/variables";

.title {
  &__intro {
    font-weight: 500;
    font-size: 20px;
    color: $primary-blue;
  }

  &__text {
    font-weight: 700;
    font-size: 40px;
    color: $black;
    margin: 10px 0;
    @media screen and (max-width: $tablet) {
      font-size: 30px;
    }
  }

  &__desc {
    font-weight: 500;
    font-size: 20px;
    color: #888888;

    a {
      color: $primary-blue;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

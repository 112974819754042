@import "../../atoms/variables";

.contact-us {
    background-repeat: repeat;
    background-position: center;
    background-size: 5%;
    padding: 15vh 0;
    position: relative;

    @media screen and (max-width: $tablet) {
        background-size: 15%;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        text-align: center;

        p {
            color: #002E94;
            font-weight: 500;
            font-size: 20px;
        }

        h2 {
            font-weight: 700;
            font-size: 40px;
            color: #222222;
            margin: 0 0 30px;
            @media screen and (max-width: $tablet) {
                font-size: 30px;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 50px;
            background: $green;
            color: $white;
            font-weight: 600;
            font-size: 20px;
            transition: all 0.5s linear;

            @media screen and (max-width: $tablet) {
                font-size: 18px;
                padding: 10px 25px;
            }

            img {
                transition: all 0.3s ease;
            }

            &:hover {
                background-color: #009922;

                // img {
                //     transition: all 0.4s ease;
                //     filter: grayscale(0) brightness(38%) sepia(300%) hue-rotate(60deg) saturate(1000%);
                // }
            }
        }
    }
}
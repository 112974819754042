@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

.component {
  position: relative;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
  background-position: right;
  background-size: auto 100%;

  &.right {
    .component {
      &__content {
        flex-direction: row-reverse;

        .title {
          &__intro,
          &__text {
            color: $white;
          }
        }
      }

      &__info {
        &--price {
          &.has-installments {
            .installments {
              color: $white;
            }
            .old-price,
            .best-price {
              font-size: 18px;
              line-height: 20px;
              color: $white;
              span {
                font-size: 18px;
                line-height: 20px;
                color: $white;
                font-weight: 400;
              }
            }
            .best-price {
              span {
                margin: 0 5px;
              }
            }
          }

          .old-price {
            color: $white;

            span {
              color: $white;
            }
          }

          .best-price {
            color: $white;

            span {
              color: $green;
            }
          }
        }

        &--buttons {
          &.multi-ctas {
            a {
              &:last-of-type {
                width: 100%;
              }
            }
          }
          a {
            &:last-of-type {
              background: $white;
              color: $primary-blue;

              &:hover {
                background: $white;
                color: $primary-red;
                img {
                  transition: all 0.4s ease;
                  filter: grayscale(0) brightness(100%) sepia(300%)
                    hue-rotate(0deg) saturate(2000%);
                }
              }
            }
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    padding: 0 15px;

    &.smallHeight {
      @media screen and (min-width: $tablet) {
        max-height: 60vh;
      }
    }
    .title {
      &__desc {
        font-size: 18px;
      }
    }

    .slick-slide > div {
      text-align: center;
    }
  }

  &__banner {
    width: 50%;
    position: relative;
    text-align: center;
    overflow: hidden;
    max-height: 120vh;

    img {
      // max-height: 75vh;
      max-width: 100%;
      height: 100%;
    }

    @media screen and (max-width: $tablet) {
      width: auto;
      height: 100%;
      width: 100vw;
      overflow: hidden;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      opacity: 0.15;
      display: flex;
      align-items: center;
      justify-content: center;

      .--item {
        display: none;
      }
    }

    [class*="--item"] {
      position: absolute;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      border-radius: 2px 40px 2px 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      max-width: 150px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #222222;
      }
    }

    .--item {
      &-0 {
        top: 10%;
        right: 10%;
      }

      &-1 {
        top: 45%;
        left: 10%;
      }

      &-2 {
        top: 75%;
        right: 10%;
      }
    }
  }

  &__info {
    width: 50%;
    padding: 40px 20px 70px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;

    @media screen and (max-width: $tablet) {
      width: 100%;
      padding: 20px 20px 80px;
    }

    &--price {
      margin: 40px 0;

      &.unavailable {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &::after {
          content: "venda suspensa";
          position: absolute;
          left: 0;
          border: 6px solid #e63247;
          height: 100%;
          max-height: 70px;
          font-size: 40px;
          display: flex;
          align-items: center;
          padding: 0 25px;
          font-weight: 700;
          color: #e63247;
          text-transform: uppercase;
          transform: rotate(344deg);
          border-radius: 10px;
          box-shadow: 0 0 10px 0 #e63247;
          background: rgba(0, 0, 0, 0.2);
          @media screen and (max-width: $mobile) {
            font-size: 30px;
            height: auto;
            padding: 10px;
            width: 90%;
          }
        }
      }

      &.has-installments {
        .installments {
          font-size: 40px;
          text-transform: uppercase;
          span {
            margin: 0 0 0 15px;
          }
          b {
            color: $green;
          }
        }
        .old-price,
        .best-price {
          font-size: 18px;
          line-height: 20px;
          span {
            font-size: 18px;
            line-height: 20px;
            color: $black;
            font-weight: 400;
          }
        }
        .best-price {
          span {
            margin: 0 5px;
          }
        }
      }

      .note-price {
        margin: 30px 0 0;
        font-size: 18px;
        // text-shadow: 0 0 1px #000;
        // text-decoration: underline;
        text-transform: uppercase;
        font-weight: 800;
        // animation: colorPulseRed 5s infinite;
        color: $primary-red;

        &.small {
          font-size: 18px;
        }
        &.medium {
          font-size: 24px;
        }
        &.big {
          font-size: 32px;
        }

        &.info {
          color: $white;
          animation: colorPulseWhite 5s infinite;
        }
        &.warning {
          color: $primary-red;
          animation: colorPulseRed 5s infinite;
        }
        &.success {
          color: $green;
          animation: colorPulseGreen 5s infinite;
        }
      }

      .old-price {
        font-weight: 400;
        font-size: 20px;
        color: #888888;

        span {
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          text-decoration-line: line-through;
          color: #222222;
        }
      }

      .best-price {
        font-weight: 400;
        font-size: 20px;
        line-height: 65px;
        color: #888888;
        display: flex;
        align-items: flex-start;

        span {
          font-weight: 700;
          font-size: 55px;
          color: #002e94;
          margin: 0 0 0 5px;

          @media screen and (max-width: $tablet) {
            font-size: 35px;
          }
        }
      }
    }

    &--buttons {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      margin: 10px 0 10px;
      width: 100%;
      max-width: 50vw;

      &.multi-ctas {
        justify-content: space-between;

        .title {
          .title__text {
            font-size: 22px;
            @media screen and (max-width: $tablet) {
              font-size: 18px;
            }
          }
        }
        a {
          &:last-of-type {
            width: 100%;
          }
        }
      }

      a,
      button,
      p {
        width: 49%;
        margin: 0;
        // height: 100%;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: $white;
        border: 0;
        display: flex;
        align-items: center;

        @media screen and (max-width: $tablet) {
          width: 100%;
          gap: 5px;
          // height: auto;
          margin: 0;
          padding: 10px 0;
          font-size: 17px;
        }

        &.start-now {
          background: $green;

          &.promo {
            position: relative;
            overflow: hidden;
            display: flex;
            &::before {
              content: "PROMO";
              position: absolute;
              font-size: 13px;
              top: 18px;
              right: -35px;
              color: #e63247;
              background: #222222;
              padding: 1px 35px;
              transform: rotate(45deg);
              text-align: center;
              font-weight: 900;
              @media screen and (max-width: $tablet) {
                font-size: 11px;
                top: 14px;
              }
            }
          }
          &.sold-off {
            position: relative;
            overflow: hidden;
            background: $gray;
            display: flex;
            cursor: not-allowed;
            &:hover {
              background: $black;
              &::before {
                transition: all 0.3s linear;
                opacity: 0;
              }
            }
            &::before {
              content: "ESGOTADO";
              position: absolute;
              font-size: 14px;

              right: -25px;
              color: $primary-red;
              background: $black;
              padding: 2px 55px;
              transform: rotate(25deg);
              text-align: center;
              font-weight: 900;
              opacity: 1;
              transition: all 0.3s linear;
            }
          }
          &:hover {
            background: #009922;
          }
        }
      }
    }

    &--desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}

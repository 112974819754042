@import "../../UI/atoms/variables";

.page-404{
    &__content{
        margin: 20vh auto;
        text-align: center;
        h1{
            margin: 0 0 10px;
        }
        a{
            color: $primary-blue;
            font-size: 20px;
            text-decoration: none;
            transition: all .2s linear;
            &:hover{
                color: $primary-red;
            }
        }
    }
}
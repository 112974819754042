@import "../../atoms/variables";

.navbar {

    &__menu-bt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        position: relative;
        z-index: 2;

        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;

        background-color: unset;
        border: 0;
        box-shadow: none;
        width: 33px;
        height: 30px;

        span {
            display: block;
            width: 33px;
            height: 5px;
            margin-bottom: 5px;
            position: relative;

            background: $primary-blue;
            // background: $white; // black friday
            border-radius: 3px;

            z-index: 1;

            transform-origin: 4px 0px;

            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                opacity 0.55s ease;


            &:first-child {
                transform-origin: 0% 0%;
            }

            &:last-child {
                margin: 0;
            }

            &:nth-last-child(2) {
                transform-origin: 0% 100%;
            }
        }

        &.open {
            span {
                position: absolute;
                opacity: 1;
                &:first-child {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }

                &:nth-last-child(2) {
                    bottom: -3px;
                    left: 5px;
                    transform: rotate(-45deg);
                }

                &:last-child {
                    top: 3px;
                    right: -5px;
                    transform: rotate(45deg);
                }
            }
        }

    }
}
@import "../../atoms/variables";
@import "../../atoms/animations";

.button{
    display: flex;
    justify-content: center;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    background-color: $primary-blue;    
    transition: all 0.3s linear;

    img{
        margin: 0 12px 0 0;
    }

    &:hover { 
        background-color: $primary-red;
    }

    a {
        color: $white;
        font-weight: 600;    
        font-size: 16px;            
    }
}
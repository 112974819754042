@import "../../UI/atoms/variables";

footer {
    .footer {
        background: $primary-blue;
        // background: #000; // black friday

        &__content {
            padding: 38px 15px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            @media screen and (max-width: $tablet) {
                flex-direction: column;
            }
        }

        &__logo {
            max-width: 20%;
        }

        &__links {
            display: flex;

            .social-medias {
                list-style: none;
                margin: 0 7.5px;

                a {
                    img {
                        margin: 0;
                    }
                }
            }
        }

        &__contact {
            display: flex;
            flex-direction: column;
            a{
                text-decoration: none;
                text-align: right;
                color: $white;
                &:first-of-type{
                    margin: 0 0 5px;
                }
            }
        }

        @media screen and (max-width: $tablet) {
            &__links {
                margin: 15px 0;
            }

            &__contact {
                text-align: center;
            }
            &__logo {
                max-width: 200px;
            }
        }


    }

    .copyright {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: #888888;
        padding: 15px 0;
    }
}
@import "../../atoms/variables";

.card {
  width: 100%;
  overflow-x: hidden;
  text-decoration: none;
  position: relative;

  @media screen and (max-width: $mobile) {
    min-width: 250px;
    max-width: 100vw;
  }

  &:hover {
    .card {
      &__text {
        color: $primary-red;
        transition: all 0.3s linear;
      }

      &__flag {
        top: 0;
        right: 10%;
        opacity: 1;
        transition: all 0.3s linear;
      }
    }
  }

  &__image {
    width: 100%;
    // max-height: 494px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
      max-height: 494px;
    }
  }

  &__text {
    width: 80%;
    height: 100px;

    margin-top: -50px;
    padding: 0 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);

    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: $primary-blue;

    transition: all 0.3s linear;
  }

  &__desc {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #888888;
  }

  &__flag {
    position: absolute;
    top: -100px;
    right: -100px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95px;
    padding: 20px 10px;
    background-color: $primary-red;
    transition: all 0.3s linear;

    &--ico {
      width: 30px;
      height: 30px;
    }

    &--text {
      color: $white;
      margin: 5px 0 0;
      text-align: center;
    }
  }
}

@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

#about {
    padding: 120px 0;
    position: relative;
    // height: 80vh;
    overflow: hidden;

    @media screen and (max-width: $mobile) {
        padding: 80px 0;
    }

    .about {
        &__content {
            padding: 15px;
            display: flex;
            align-items: center;


            @media screen and (max-width: $tablet) {
                flex-direction: column-reverse;
                justify-content: center;
                align-items: flex-start;
            }

            .title.box {
                width: 50%;

                @media screen and (max-width: $tablet) {
                    width: 100%;
                }
            }
        }

        &__image {
            position: absolute;
            width: auto;
            height: auto;
            max-width: 50vw;
            max-height: 80vh;
            right: 0;

            @media screen and (max-width: $tablet) {
                max-width: unset;
                max-height: unset;
                height: 100%;
                z-index: -1;
                opacity: 0.1;
                left: 0;
                bottom: 0;
            }
        }

    }

}
$primary-blue: #002E94;
$primary-red: #E63247;

$gray: #3e3e3e;
$black: #222222;
$white: #ffffff;
$green: #00BF2A;

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font1: 'DM Serif Display', serif;
$font2: 'Montserrat', sans-serif;

$desktop: 1300px;
$tablet: 1024px;
$mobile: 768px;
@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

#references {
    .references {
        &__content {
            display: flex;
            padding: 80px 0;
            justify-content: space-between;
            @media screen and (max-width: $tablet){
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &__item {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px 20px;
            p {
                text-align: center;
                color: $white;
            }

            &--number {
                font-weight: 700;
                font-size: 60px;
                margin: 0 0 10px
            }

            &--text {
                font-weight: 400;
                font-size: 20px;
            }
        }
    }
}
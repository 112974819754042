@import "../../../UI/atoms/variables";

.slick-slide {
  .insta-post {
    transform: translateX(85%) scale(0.3);
  }
  &.slick-active {
    .insta-post {
      transform: translateX(0%) scale(1);
    }
  }
}

.insta-post {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  background: #fff;
  overflow: hidden;
  transition: all 0.6s linear;
  &__header {
    display: flex;
    align-items: center;
    padding: 12px 12px;
    .header-image {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      img {
        height: 105%;
        width: auto;
      }
    }
    p {
      font-weight: 600;
      font-size: 14px;
      margin: 0 0 0 14px;
      padding: 6px 0;
      color: $black;
      text-decoration: none;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 400px;
    width: 400px;
    max-width: 100%;
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
    img {
      height: 110%;
    }
  }
  &__footer {
    display: flex;
    svg {
      width: 40px;
      height: 40px;
      padding: 12px;
      &:last-of-type {
        margin: 0 0 0 auto;
      }
    }
  }
}

@import "../../UI/atoms/variables";

body {
  background-color: #f7f7f7;

  #header {
    background-color: $white;
    max-height: unset;
  }

  #about.about__content.course {
    padding: 150px 15px 10px;
    @media screen and (max-width: $tablet) {
      padding: 250px 15px 0;
    }
  }

  #about.course,
  #commertialText.course {
    padding: 150px 15px 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #222222;
    max-width: 1200px;

    ul {
      margin: 10px 0 0;
      li {
        list-style: none;
        position: relative;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $desktop) {
          font-size: 14px;
        }
        &::before {
          content: "";
          height: 20px;
          width: 20px;
          background-image: url("https://www.institutovalquiriaarenas.com.br/img/course/icons/check-module.svg");
          background-size: contain;
          background-repeat: no-repeat;
          margin: 0 25px;

          @media screen and (max-width: $desktop) {
            margin: 5px;
          }
        }
      }
    }
  }
  #commertialText.course {
    padding: 20px 0 80px;
    @media screen and (max-width: $tablet) {
      padding: 20px 15px 80px;
    }
  }

  #public {
    background-color: unset;
  }
}

@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

#call-to-offer {
  position: relative;
  display: grid;
  place-items: center;
  padding: 20px 0;
  text-decoration: none;

  .title {
    text-align: center;
  }

  .call-to-offer {
    &__container {
      position: relative;
      display: flex;
      align-items: baseline;
      justify-content: space-around;
      border-radius: 10px;
      width: 100%;
      max-width: 1270px;
      padding: 20px 15px;
      background-size: auto 100%;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
        padding: 80px 20px 60px;
        border-radius: 0;
      }

      &--flag {
        position: relative;
        top: -20px;
        background-color: $primary-red;
        display: grid;
        place-items: center;
        max-width: 140px;
        // height: 100px;

        color: #fff;
        font-size: 15px;
        text-align: center;

        padding: 5px;

        border-radius: 0 0 20% 0px;

        @media screen and (max-width: $tablet) {
          position: absolute;
          top: 0;
          left: 0px;
          font-size: 12px;
          max-width: 80%;
          max-height: 80px;
          border-radius: 0 0 25px 0;
          padding: 5px 10px;
        }
      }

      .title.box {
        height: 100%;
        align-self: center;

    

        @media screen and (max-width: $mobile) {
          margin: 0 0 30px;
        }

        .title__text {
          color: $green;    
          @media screen and (max-width: $mobile) {
            font-size: 25px;
          }
        }
        .title__intro,
        .title__desc {
          font-size: 18px;
          color: #fff;
        }
      }

      &--button {
        background-color: #fff;
        border: 1px solid #fff;
        width: 200px;
        padding: 10px;
        font-size: 20px;
        color: $primary-blue;
        text-align: center;
        font-weight: 800;
        border-radius: 10px;
        transition: all 0.3s linear;
        align-self: center;
        &:hover {
          color: $primary-red;
          animation: shadowRotateRed 1s linear infinite;
          span {
            animation: point 1s infinite linear;
          }
        }
        @media screen and (max-width: $mobile) {
          color: $primary-red;
          border: 1px solid $primary-red;
        }
      }
    }
  }
}

@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

#app {
  padding: 95px 0 0;
  @media screen and (max-width: $tablet) {
    padding: 70px 0 0;
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  // background-color: #000; // black friday
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  z-index: 10;
  height: 95px;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    padding: 15px;
    .menus {
      display: flex;
      gap: 45px;
      flex-direction: row;
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  &__links,
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0 0 0 0;

    &--link,
    &--dropdown {
      margin: 0 20px;

      a,
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: $black;
        // color: $white; //black friday
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $primary-red;
          &::after {
            width: 100%;
          }
        }
      }
    }
    &--dropdown {
      &:hover {
        .dropdown {
          &--label {
            color: $primary-red;
          }
          &--links {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s ease;
            &::before {
              opacity: 1;
              transition: all 0.3s ease;
            }
          }
        }
      }

      .dropdown {
        &--links {
          position: absolute;
          display: flex;
          top: 82%;
          flex-direction: column;
          background-color: #fff;
          box-shadow: 0 0 10px 0 #c9c9c9;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease;
          z-index: 1;

          &::before {
            content: "";
            height: 30px;
            width: 30px;
            position: absolute;
            top: -10px;
            left: 20px;
            background-color: #dbdbdb;
            transform: rotate(45deg);
            box-shadow: 0 0 2px 0 #c9c9c9;
            z-index: -1;
            opacity: 0;
            transition: all 0.3s ease;
          }

          .link {
            padding: 15px 45px;
            background: #fff;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: $black;
            text-decoration: none;
            &:hover {
              color: $primary-red;
              &::after {
                width: 100%;
              }
            }
            &:first-child {
              padding: 30px 45px 15px;
            }
            &:last-child {
              padding: 15px 45px 30px;
            }
          }
        }
      }
    }

    .--button {
      margin: 0;
      width: 200px;
      max-width: 100%;
      a {
        color: $white;
        font-weight: 600;
      }
    }
  }

  &__logo {
    max-width: 20%;
    z-index: 2;
    position: relative;
    &--img {
      width: 100%;
    }
    .black-friday {
      position: absolute;
      max-width: 100px;
      right: -45px;
      bottom: -8px;
      transform: rotate(-15deg);

      @media screen and (max-width: $tablet) {
        max-width: 75px;
        right: -35px;
        bottom: 0px;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    // height: 70px;
    height: 90px;

    &__logo {
      max-width: 50%;
      max-height: 100%;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &--img {
        width: auto;
        max-height: 45px;
      }
    }

    &__menu-bt {
      &.open ~ .navbar__links-box {
        right: 0;
        transition: all 0.5s linear;
      }
    }

    &__links-box {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0px;
      right: -110vw;
      background: rgba(255, 255, 255, 0.95);
      // background: #000; // black friday
      z-index: 1;
      transition: all 0.5s linear;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__links,
    &__buttons {
      margin: 0;
      flex-direction: column;
      // gap: 20px;

      &--link,
      &--button {
        padding: 20px 0;

        a {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    &__buttons {
      width: 100%;

      .--button {
        width: 100%;

        a {
          color: $white;
          font-weight: 600;
        }
      }
    }
  }
}

@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

#header {
  position: relative;
  max-height: 80vh;
  min-height: 400px;

  @media screen and (max-width: $tablet) {
    max-height: unset;
  }

  .header {
    &__banners {
      width: 100vw;
      overflow: hidden;
    }

    &__content {
      width: 100vw;
      max-width: 100vw;
      background-repeat: repeat-y;
      // background-color: #c9c9c9;
      background-position: right center;
      background-size: 80%;
      overflow: hidden;
      .slick-slider {
        .slick-current {
          padding: 0 4vw 0 5vw;
        }
        .slick {
          &-disabled {
            opacity: 0.1;
            cursor: initial;
            @media screen and (max-width: $mobile) {
              opacity: 0;
            }
          }
          &-next {
            right: 5%;
          }
          &-prev {
            left: 2%;
            @media screen and (max-width: $mobile) {
              left: 1%;
            }
          }
        }
      }
    }
  }

  button {
    cursor: pointer;
    transition: opacity 0.5s linear;
    border: 0;
    height: 0;
  }
}

@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

.tabLayout {
  background-color: #fff;
  padding: 30px 10%;

  @media screen and (max-width: $tablet) {
    padding: 10px;
  }
  &__content {
    display: flex;
    margin: 30px auto 10px;
    @media screen and (max-width: $tablet) {
      margin: 30px 0;
      flex-direction: column;
    }

    .title {
      text-align: center;
      margin: 0 0 30px;
    }

    .tabLayout {
      &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;


        &::-webkit-scrollbar {
          height: 1px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1; 
        }
         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #ff596c; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #E63247; 
        }

        @media screen and (max-width: $tablet) {
          flex-direction: row;
          justify-content: flex-start;
          overflow: auto;
        }

        &--item {
          color: $primary-blue;
          cursor: pointer;
          padding: 15px 10px;
          font-size: 18px;
          width: 225px;
          text-align: left;
          padding: 15px 10px 15px 20px;
          border-radius: 0 0 0 30px;
          box-shadow: 5px 4px 7px -2px #d4d4d4;
          
          // transition: all 0.5s ease-in-out;
          @media screen and (max-width: $tablet) {
            display: flex;
            align-items: center;
            padding: 15px 10px;
            font-size: 15px;
            width: auto;
            max-width: unset;
            white-space: nowrap;
            margin: 2px 0 0;
            border-radius: 10px 10px 0 0;
            box-shadow: -1px 0px 2px 0px #d4d4d4;
          }

          &.open {
            background-color: $primary-red;
            color: #fff;
            padding: 15px 10px 15px 20px;
            transition: all 0.4s linear, color 0s linear;
            @media screen and (max-width: $tablet) {
              padding: 15px 10px;
              text-align: center;
              border-radius: 10px 10px 0 0;
              display: flex;
              align-items: center;
            }
          }
        }
      }
      &__info {
        position: relative;
        background-color: $white;
        box-shadow: 1px 1px 5px 1px #c9c9c9;
        padding: 45px 35px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        height: 500px;
        @media screen and (max-width: $tablet) {
          height: auto;
        }

        &--content {
          padding: 0;
          max-width: unset;
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 15px;
          max-width: 0;
          max-height: 0;
          overflow: hidden;

        @media screen and (max-width: $tablet) {
          height: 0;
        }

          &.open {
            height: auto;
            max-height: 600px;
            max-width: 100vw;
            transition: max-height 0.8s ease-in-out;
          }
        }
        &::before {
          content: "";
          height: 100%;
          width: 2px;
          position: absolute;
          background-color: $primary-red;
          top: 0;
          left: -2px;
          bottom: 0;
          right: unset;

          @media screen and (max-width: $tablet) {
            width: 100%;
            height: 2px;
            top: -2px;
            left: 0;
            right: 0;
            bottom: unset;
          }
        }
        a.see-reviews {
          font-size: 18px;
          margin: 15px 0 0;
          color: $primary-red;

          @media screen and (max-width: $tablet) {
            font-size: 14px;
          }
        }
        .event-complet{
          font-size: 25px;
          color: $primary-red;
        }

        p {
          font-size: 18px;
          @media screen and (max-width: $tablet) {
            font-size: 15px;
            br {
              margin: 10px 0;
            }
          }
          b {
            font-size: 22px;
            line-height: 42px;
            color: $primary-blue;
            @media screen and (max-width: $tablet) {
              font-size: 18px;
              line-height: inherit;
            }
          }
        }

        * {
          font-size: 20px;
        }
        ul {
          margin: 10px 0 0;
          * {
            font-size: 15px;
          }
          li {
            list-style: none;
            margin: 5px 0;
            ul {
              margin: 10px 0;
              li {
                margin: 5px 0;
                list-style: inside circle;
              }
            }
          }
        }
      }
    }
  }
}

@import "./components/UI/atoms/_variables.scss";

* {
  margin: 0;
  padding: 0;
  font-family: $font2;
  box-sizing: border-box;
  outline: unset;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Padrão */
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-blue;
  }
}

[class*="content"] {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 15px;
  @media screen and (max-width: $desktop) {
    margin: 0;
    padding: 15px;
  }
}

.slick-arrow {
  &.slick {
    &-prev,
    &-next {
      height: 30px;
      z-index: 1;
      &::before {
        content: unset;
      }
    }
    &-prev {
      @media screen and (max-width: $tablet) {
        left: 0;
      }
    }
    &-next {
      @media screen and (max-width: $tablet) {
        right: 0;
      }
    }
  }
}

@import "../../atoms/variables";
@import "../../atoms/animations";

.link{
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    img{
        margin: 0 12px 0 0;
    }

    &::after{
        content: "";
        width: 0;
        height: 1px;
        background-color: $primary-red;       
        transition: all 0.5s linear;
    }

    &:hover { 
        color: $primary-red;
        // &::after{
        //     // width: 100%;
        // }
    }
}
@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

#service {
    padding: 100px 0;

    &.advisory {
        background-color: #E5E5E5;
    }

    @media screen and (max-width: $mobile) {
        padding: 80px 0;
    }

    .title {
        text-align: center;
        margin: 0 0 60px;

        &__desc {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #666666;
            margin: 30px 0 0;
        }
    }

    .service {
        &__info {
            &--title {
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                color: #002E94;
                margin: 0 0 30px;
            }

            &--list {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;

                .item {
                    width: 23%;
                    background: #e5e5e5;
                    padding: 20px 20px;
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    @media screen and (max-width: $tablet) {
                        min-width: 240px;
                        width: 48%;
                    }
                    // @media screen and (max-width: $mobile) {
                    //     width: 50%;
                    // }

                    h3 {
                        font-weight: 400;
                        font-size: 16px;
                        text-align: center;
                        color: #222222;
                    }
                }
            }
        }
    }
}
@keyframes rotateRight {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

@keyframes rotateLeft {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}

@keyframes comeBack {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes comeBackLeft {
  0% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes comeBackRight {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bouncing {
  0% {
    transform: scaleY(0);
  }
  25% {
    transform: scaleY(0.7);
  }
  50% {
    transform: scaleY(0.6);
  }
  75% {
    transform: scaleY(0.8);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes rotateLeft {
  0% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotateRight {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes shadowRotateRed {
  0% {
    box-shadow: 0 2px 0 0.5px $primary-red;
  }
  25% {
    box-shadow: 2px 0 0 0.5px $primary-red;
  }
  50% {
    box-shadow: 0 -2px 0 0.5px $primary-red;
  }
  75% {
    box-shadow: -2px 0 0 0.5px $primary-red;
  }
  100% {
    box-shadow: 0 2px 0 0.5px $primary-red;
  }
}
@keyframes shadowRotateGreen {
  0% {
    box-shadow: 0 2px 5px 1px $green;
  }
  25% {
    box-shadow: 2px 0 5px 1px $green;
  }
  50% {
    box-shadow: 0 -2px 5px 1px $green;
  }
  75% {
    box-shadow: -2px 0 5px 1px $green;
  }
  100% {
    box-shadow: 0 2px 5px 1px $green;
  }
}

@keyframes point {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 10px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes colorChangeRed {
  0% {
    color: $primary-red;
  }
  50% {
    color: #d10202;
  }
  100% {
    color: $primary-red;
  }
}

@keyframes colorPulseRed {
  0%,
  100% {
    color: $primary-red; // Cor inicial e final
  }
  50% {
    color: #ce2121; // Cor intermediária no auge do pulso
  }
}

@keyframes colorPulseGreen {
  0%,
  100% {
    color: $green; // Cor inicial e final
  }
  50% {
    color: #007e1b; // Cor intermediária no auge do pulso
  }
}

@keyframes colorPulseWhite {
  0%,
  100% {
    color: $white; // Cor inicial e final
  }
  50% {
    color: rgb(163, 163, 163); // Cor intermediária no auge do pulso
  }
}


@keyframes goRigth {
  0% {
    transform: translateY(0) translateX(0);
  }
  85% {
    transform: translateY(0) translateX(90px);
  }
  86% {
    transform: translateY(-200px) translateX(-90px);
  }
  87% {
    transform: translateY(0) translateX(-90px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

@keyframes letsGo {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(35%);
  }
  100% {
    transform: translateX(0);
  }
}

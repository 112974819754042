@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

.courses {
    background-color: #FAFAFA;

    .courses {
        &__content {
            padding: 80px 30px;

            @media screen and (max-width: $tablet) {
                padding: 80px 30px;
            }

            .title {
                text-align: center;
                margin: 0 0 40px;

                &__desc {
                    max-width: 750px;
                    margin: 0 auto;
                }
            }
        }

        &__list {
            // display: flex;
            // justify-content: flex-start;
            // overflow-x: auto;
            // -webkit-overflow-scrolling: scroll;

            &--item {
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0 5px;

                @media screen and (max-width: $tablet) {
                    padding: 0 30px;
                }

                p {
                    text-align: center;
                    color: $white;
                }

                &--number {
                    font-weight: 700;
                    font-size: 60px;
                    margin: 0 0 10px
                }

                &--text {
                    font-weight: 400;
                    font-size: 20px;
                }
            }
        }
    }
}

@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

#teachers {
    background-color: #FAFAFA;
    padding: 80px 0 80px;

    .teachers {
        &__content {
            padding: 0;

            @media screen and (max-width: $tablet) {
                padding: 0 40px;
            }

            .title {
                text-align: center;
                margin: 0 0 40px;
                h2{
                    font-size: 20px;
                }
                &__desc {
                    max-width: 750px;
                    margin: 0 auto;
                }
            }
        }

        &__list {
            &--item {
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                padding: 0 10px;
                justify-content: flex-start;
                .card{
                    &__image{
                        height: 100%;
                        width: 100%;
                        max-height: 300px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                p {
                    text-align: center;
                    color: $white;
                }

                &--number {
                    font-weight: 700;
                    font-size: 60px;
                    margin: 0 0 10px
                }

                &--text {
                    font-weight: 400;
                    font-size: 20px;
                }
            }
        }
    }
}
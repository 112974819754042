@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

#partners {
    margin: 100px auto;
    max-width: 1052px;

    .title {
        text-align: center;
        margin: 0 0 30px;
    }

    .partners {
        &__list {
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;

            &--item {
                width: 32%;
                padding: 40px 20px;
                margin: .5% .5%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                transition: all .3s linear;
                height: 180px;
                @media screen and (max-width: $tablet) {
                    width: 49%;
                }

                @media screen and (max-width: $tablet) {
                    width: 100%;
                }

                img {
                    max-height: 95px;
                    max-width: 60%;
                }

                &:hover {
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, .1);
                }
            }
        }
    }
}
@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

.imersão-acesso-vascular-para-hemodiálise-e-hdf,
.empowernefro-2025 {
  #public {
    .public__content {
      @media screen and (max-width: $mobile) {
        padding: 230px 15px 80px;
      }
      @media screen and (max-width: $tablet) and (min-width: $mobile) {
        padding: 200px 15px 80px;
      }
      padding: 100px 15px 80px;
    }
  }
}

#public {
  background-color: #fafafa;
  .public {
    &__content {
      padding: 80px 0;
      @media screen and (max-width: $tablet) {
        padding: 80px 15px;
      }
      .title {
        text-align: center;
        margin: 0 0 40px;
        h2 {
          font-size: 20px;
        }
      }
    }
    &__list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    &__item {
      width: 23%;
      margin: 0 1%;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px 10px;
      height: 162px;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      transition: all 0.5s linear;
      &:hover {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      }
      @media screen and (max-width: $tablet) {
        width: 48%;
        margin: 10px 0;
      }

      p {
        text-align: center;
        color: $black;
      }

      &--title {
        margin: 10px 0 0;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
      }
      &--desc {
        font-size: 14px;
        text-align: center;
        color: #888888;
      }
    }
  }
}

@import "../../UI/atoms/variables";
@import "../../UI/atoms/animations";

#benefits {
    background-color: #FAFAFA;
    .benefits {
        &__content {
            padding: 80px 0 80px;
            @media screen and (max-width: $tablet){
                padding: 80px 15px 80px;
            }
            .title {
                text-align: center;
                margin: 0 0 40px;
            }
        }
        &__list{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &__item {
            width: 18%;
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 25px 10px;
            border: 1px solid transparent;
            transition: all 0.5s linear;

            &:hover{

                // background: #FFFFFF;
                border: 1px solid #F1F1F1;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
                border-radius: 2px;
            }

            @media screen and (max-width: $tablet){
                width: 48%;
                margin: 10px 0;
                justify-content: space-around;
            }

            p {
                text-align: center;
                color: $black;
            }

            &--title {
                margin: 15px 0 10px;
                font-weight: 500;
                font-size: 16px;
                text-align: center;
            }
            &--desc {
                font-size: 14px;
                text-align: center;
                color: #888888;
            }
        }
    }
}
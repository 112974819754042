@import "../../UI/atoms/variables";

body.others-services-page {
    background-color: $white;

    #banner {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        position: relative;

        img {
            width: auto;
            min-width: 100vw;
        }
        .title{
            background-color: #fff;
            text-align: center;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;

            margin: auto;
            padding: 10px;
            width: 416px;
            height: 93px;
            max-width: 90vw;
        
            &__text{
                margin: 0;
            }
        }
    }

}
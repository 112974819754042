@import "../../UI/atoms/variables";

body.clients-partners-page {
    background-color: $white;

    #banner {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;

        img {
            width: auto;
            min-width: 100vw;
        }
    }

    #clients {
        padding: 80px 15px 40px;

        .clients {
            &__list {
                display: flex;
                justify-content: center;
                align-items: stretch;
                flex-wrap: wrap;
                gap: 20px;

                &--item {
                    width: 32%;
                    list-style: none;
                    background: #FAFAFA;
                    border: 1px solid #F1F1F1;
                    padding: 10px 20px;
                    margin: 0;

                    @media screen and (max-width: $tablet) {
                        width: 48%;
                    }

                    @media screen and (max-width: $mobile) {
                        width: 100%;
                    }

                    .title {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        height: 100%;

                        &__text {
                            order: 1;
                            font-weight: 600;
                            font-size: 20px;
                            color: #002E94;
                            text-align: center;
                            vertical-align: middle;
                            min-height: 50px;
                        }

                        &__intro {
                            order: 2;
                            font-weight: 500;
                            font-size: 14px;
                            text-align: center;
                            color: #E63247;
                        }

                        &__desc {
                            order: 3;
                            font-weight: 500;
                            font-size: 14px;
                            text-align: center;
                            color: #888888;
                            min-height: 80px;
                            margin: 0;
                        }
                    }

                }
            }
        }
    }

    #partners {
        padding: 40px 15px 80px;

        .partners {
            &__list {
                row-gap: 20px;

                &--item {
                    width: 25%;
                    padding: 20px;
                    flex-direction: column;
                    height: auto;
                    margin: 0;
                    position: relative;

                    border-left: 2px solid #F1F1F1;
                    border-bottom: 1px solid #F1F1F1;

                    &:nth-child(4n+1) {
                        border-left: none;
                    }
                    &:last-of-type{
                        border-right: 2px solid #F1F1F1;
                        border-left: 2px solid #F1F1F1;
                    }

                    @media screen and (max-width: $tablet) {
                        width: 33%;
                        &:nth-child(1n){
                            border-left: 2px solid #F1F1F1;
                            border-bottom: 1px solid #F1F1F1;
                        }
                    }

                    @media screen and (max-width: $mobile) {
                        width: 100%;
                    }


                    .text {
                        font-weight: 700;
                        font-size: 24px;
                        text-align: center;
                        color: #888888;
                    }

                    .link {
                        font-weight: 500;
                        font-size: 14px;
                        text-align: center;
                        color: #002E94;
                    }
                }
            }
        }
    }

    #clients,
    #partners {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1300px;

        @media screen and (max-width: $tablet) {
            margin: 0;
        }

        .title {
            margin: 0 0 30px;
            text-align: center;
        }


    }
}
@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

#reviews {
  background-image: linear-gradient(181deg, #fff, transparent);
  padding: 80px 0 30px;
  &.reviews-top {
    @media screen and (max-width: $tablet) {
      padding: 240px 0 35px;
    }
  }

  .reviews {
    &__content {
      padding: 0;

      @media screen and (max-width: $tablet) {
        padding: 0 40px;
      }

      .title {
        text-align: center;
        margin: 0 0 40px;
        h2 {
          font-size: 20px;
        }
        &__desc {
          max-width: 750px;
          margin: 0 auto;
          a {
            color: $primary-blue;
            font-size: 19px;
            font-weight: 600;

          }
        }
      }
    }

    &__list {
      &--item {
        position: relative;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0 10px;
        .card {
          &__image {
            height: 100%;
            width: 100%;
            max-height: 500px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            iframe {
              width: 100%;
              height: 550px;
              max-height: 100vw;

              .img {
                width: auto;
              }
            }
            img {
              width: 100%;
              height: auto;
              max-height: 100vw;
            }
          }
          &__text {
            height: auto;
            max-width: 90%;
            padding: 40px 20px;
            margin: -40px 0 0;
            display: block;
            flex-direction: column;
            color: #888888;
            z-index: 1;
            b {
              color: #002e94;
            }
          }
        }
      }
    }
  }
}

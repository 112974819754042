@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

#questions {
  margin: 100px auto;
  max-width: 860px;
  .title {
    text-align: center;
    margin: 0 0 30px;
  }
  .questions {
    &__list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        width: 100%;
        padding: 15px 20px;
        background: #ffffff;
        border: 1px solid #f1f1f1;

        &.open {
          background: #fafafa;
          .title {
            color: $primary-blue;
            &::after {
              content: "-";
            }
          }
          .text {
            width: 100%;
            visibility: visible;
            opacity: 1;
            max-height: 350px;
            margin-top: 10px;
            @media screen and (max-width: $tablet) {
                max-height: 575px;
            }
          }
        }

        .title {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          padding: 0 50px 0 0;
          transition: all 0.3s;

          &::after {
            content: "+";
            position: absolute;
            right: 0;
            width: 10px;
            height: 10px;
            opacity: 1;
            font-weight: 500;
            font-size: 16px;
            transition: all 0.3s;
          }
        }

        .text {
          visibility: hidden;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: all 0.3s ease-in;

          p {
            font-size: 17px;
            font-weight: 300;
            color: #7e838c;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

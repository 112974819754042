@import "../../atoms/variables";
@import "../../atoms/animations";

.slick-slide {
  .header__banner--item {
    .banner__text-area {
      .banner {
        &__text {
          transform: translateY(-100%);
        }
        &__link {
          transform: translateY(100%);
        }
      }
    }
  }
  &.slick-active {
    .header__banner--item {
      .banner__text-area {
        .banner {
          &__text {
            transform: translateY(0%);
          }
          &__link {
            transform: translateY(0%);
          }
        }
      }
    }
  }
}

.header {
  &__banner--item {
    width: 100%;
    max-width: 1200px;
    padding: 20px 15px;
    position: relative;
    @media screen and (max-width: $desktop) {
      width: 96.5%;
      padding: 20px 40px 5px 45px;
    }
    @media screen and (max-width: $desktop) {
      width: 96.5%;
      padding: 20px 12px 5px 0;
    }
    // padding: 0;
    // margin: 0;
    display: flex;
    .banner {
      &__text-area {
        @media screen and (max-width: $tablet) {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          padding: 15px 12px;
          border-bottom: 1px solid #dbdbdb;
          text-decoration: none;
        }
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .banner {
          &__text {
            font-weight: 700;
            font-size: 25px;
            color: $black;
            margin: 10px 0;
            max-width: 95%;
            transition: all 0.8s linear;
            @media screen and (max-width: $tablet) {
              font-size: 17px;
              text-align: left;
              margin: 0;
              font-weight: 600;
              color: $primary-blue;
              margin: 0 14px 0 0;
            }
          }
          &__link {
            margin-top: 20%;
            display: flex;
            justify-content: center;
            padding: 15px;
            text-align: center;
            text-decoration: none;
            border-radius: 5px;
            color: $white;
            width: 300px;
            max-width: 100%;
            font-weight: 600;
            font-size: 20px;
            background-color: $primary-blue;
            border: 2px solid $primary-blue;
            transition: all 0.8s linear, background-color 0.3s linear,
              color 0.3s linear;
            @media screen and (max-width: $tablet) {
              font-size: 18px;
              margin: 10% 0 5%;
              text-align: center;
            }
            &:hover {
              color: $primary-blue;
              background-color: $white;
            }
          }
          &__go {
            width: 70px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .container {
              display: flex;
              flex-wrap: nowrap;
              animation: letsGo 2s linear infinite;
              svg {
                height: 22px;
                width: auto;
              }
            }
          }
        }
      }
      &__images {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        overflow: hidden;
        @media screen and (max-width: $tablet) {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

#advantages {
  background-color: $white;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 3;
  padding: 30px 30px;
  max-width: 90%;
  margin: -50px auto 0;

  .advantages {
    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1270px;
      margin: 0 auto;
    }
    &__item {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 10px;
      border: 1px solid transparent;

      @media screen and (max-width: $tablet) {
        margin: 10px 0;
      }

      p {
        text-align: center;
        color: $primary-blue;
      }

      &--img {
        margin: 0 10px 0 0;
      }

      &--title {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        @media screen and (max-width: $tablet) {
          font-size: 16px;
        }
      }
    }
  }
}

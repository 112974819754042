@import "../../../UI/atoms/variables";
@import "../../../UI/atoms/animations";

#assurance {
    margin: 100px auto 0;
    text-align: center;
    max-width: 979px;
    .title{
        text-align: center;
        margin: 0 0 30px;
    }
    img{
        @media screen and (max-width:$mobile){
            max-width: 100vw;
            width: 100%;
        }
    }
}